<template lang="pug">
  div
    template(v-if="orderCategory === 'fabric_shade'")
      production-order-preview-fabric-shade-table(:order="order")
    template(v-else-if="orderCategory === 'external_shutter'")
      production-order-preview-external-shutter-table(:order="order")
    template(v-else-if="orderCategory === 'fabric_shade_day_night'")
      production-order-preview-fabric-shade-day-night-table(:order="order")
    template(v-else-if="orderCategory === 'pleat'")
      production-order-preview-pleat-table(:order="order")
    template(v-else-if="orderCategory === 'detail'")
      production-order-preview-detail-table(:order="order")
    template(v-else-if="orderCategory === 'frame_mosquito_net'")
      production-order-preview-frame-mosquito-net-table(:order="order")
    template(v-else-if="orderCategory === 'roll_mosquito_net'")
      production-order-preview-roll-mosquito-net-table(:order="order")
    template(v-else) Brak danych

</template>

<script>
import ProductionOrderPreviewFabricShadeTable from "./ProductionOrderPreviewFabricShadeTable";
import ProductionOrderPreviewFabricShadeDayNightTable from "./ProductionOrderPreviewFabricShadeDayNightTable";
import ProductionOrderPreviewExternalShutterTable from "./ProductionOrderPreviewExternalShutterTable";
import ProductionOrderPreviewPleatTable from "./ProductionOrderPreviewPleatTable";
import ProductionOrderPreviewDetailTable from "./ProductionOrderPreviewDetailTable";
import ProductionOrderPreviewFrameMosquitoNetTable from "./ProductionOrderPreviewFrameMosquitoNetTable.vue";
import ProductionOrderPreviewRollMosquitoNetTable from "./ProductionOrderPreviewRollMosquitoNetTable.vue";

export default {
  components: {
    ProductionOrderPreviewRollMosquitoNetTable,
    ProductionOrderPreviewFrameMosquitoNetTable,
    ProductionOrderPreviewFabricShadeTable,
    ProductionOrderPreviewFabricShadeDayNightTable,
    ProductionOrderPreviewExternalShutterTable,
    ProductionOrderPreviewPleatTable,
    ProductionOrderPreviewDetailTable
  },
  props: {
    order: {default: [], required: true},
  },
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    orderCategory() {
      if (!this.order) {
        return null;
      }

      return this.order.category;
    },
    isFixedOrder() {
      if (!this.order) {
        return false;
      }

      return this.order['@type'] === 'FixedOrder';
    }
  }
};
</script>
