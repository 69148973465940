<template lang="pug">
  div Logistyka
    app-table-store(store-namespace="logisticList")
      template(#filters="props")
        .columns
          .column
            app-table-filter-select(
              label="Kategoria"
              field="category"
              v-bind="props"
            )
              option(:value="null") Wszystkie
              option(:value="orderCategoryByProductTypeCode.RTK") Roletki Tkaninowe
              option(:value="orderCategoryByProductTypeCode.RDN") Roletki dzień noc
              option(:value="orderCategoryByProductTypeCode.RZW") Rolety Zewnętrzne
              option(:value="orderCategoryByProductTypeCode.PLI") Plisy
              option(:value="orderCategoryByProductTypeCode.MSQ") Moskitiery ramkowe
              option(:value="orderCategoryByProductTypeCode.MZW") Moskitiery Zwijane
              option(:value="orderCategoryByProductTypeCode.MPL") Moskitiery Plisowane
              option(:value="orderCategoryByProductTypeCode.MPR") Moskitiery Przesówne
              option(:value="orderCategoryByProductTypeCode.MDR") Moskitiery Drzwiowe
          .column
            app-table-filter-text(
              label="Numer zamówienia"
              field="number"
              v-bind="props"
              :time="1000"
            )
          .column
            app-table-filter-text(
              label="Firma"
              field="companiesShortNames"
              v-bind="props"
            )
          .column
            app-table-filter-select(
              label="Metoda dostawy"
              field="deliveryMethod.id"
              v-bind="props"
            )
              option(v-for="optionValue in deliveryMethodFilterValues" :value="optionValue.value") {{ optionValue.label }}

      template(#headers="props")
        th.has-max-width-20
          b-checkbox(@input="checkAll($event, props.list)" :value="selectedOrders.length === props.list.length")
        app-table-head(v-bind="props" label="Kategoria")
        app-table-head(v-bind="props" label="Firma")
        app-table-head(v-bind="props" label="Nr zamówienia")
        app-table-head(v-bind="props" label="Sztuk")
        app-table-head(v-bind="props" label="Waga")
        app-table-head(v-bind="props" label="Data Produkcji")
        app-table-head(v-bind="props" label="Data wysyłki")
        app-table-head(v-bind="props" label="Sposób dostawy")

      template(#default="{ row, perPage, total }")
        td.has-max-width-20
          b-checkbox(v-model="selectedOrders" :native-value="row.id")
        td {{ getOrderCategory(row) }}
        td {{ getCompanyInfo(row) }}
        td {{ orderNumber(row.number) }}
        td {{ countOrderItems(row) }}
        td {{ getOrderWeight(row) }}
        td {{ getOrderDate(row) }}
        td
          date-picker(v-model="row.shipmentDate" type="day" value-type="format" format="YYYY-MM-DD" placeholder="Wybierz" :clearable="false")
        td {{ getOrderDeliveryMethod(row) }}
        td
          b-button(size="is-small" type="is-info" @click="showOrder(row)")
            b-icon(size="is-small" icon="eye")
          b-button(size="is-small" type="is-primary" @click="editOrder(row)")
            b-icon(size="is-small" icon="pencil")
    br
    .divider
    br
    .columns.is-multiline
      .column
        b-checkbox(v-model="ordersAreConfirmed" required) Zatwierdzam ustawienie daty wysyłki dla wybranych zamówień
      .column.is-12
        b-button(type="is-primary" @click="confirmOrders") Zatwierdź
    br
    .divider
    br
    ProductionOrderPreview(:active="isModalActive" :order-id="orderId" @close="isModalActive=false")
    ProductionOrderEdit(:active="isEditOrderModalActive" :order-id="editOrderId" @close="onEditOrderClose" :is-logistic="true")
</template>

<script>
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import orderNumberFlatter from "../../../helpers/orderNumberFlatter";
import ProductionOrderPreview from "../../common/components/ProductionOrderPreview";
import productTypeFullNameByOrderCategory from "../../../helpers/productTypeFullNameByOrderCategory";
import moment from "moment";
import notify from "../../../helpers/notify";
import http from "../../../http";
import DatePicker from "vue2-datepicker";
import ProductionOrderEdit from "../../common/components/ProductionOrderEdit.vue";
import orderItemGetter from "../../../helpers/orderItemGetter";
import orderCategoryByProductTypeCode from "../../../helpers/orderCategoryByProductTypeCode";
export default {
  components: {ProductionOrderEdit, ProductionOrderPreview, DatePicker },
  data() {
    return {

      selectedOrders: [],
      ordersAreConfirmed: false,
      deliveryMethodFilterValues: [],
      isModalActive: false, //od podgladu
      orderId: null, //od podgladu
      isEditOrderModalActive: false, //od edycji
      editOrderId: false //od edycji
    };
  },
  computed: {
    orderCategoryByProductTypeCode() {
      return orderCategoryByProductTypeCode;
    }
  },
  methods: {
    getOrderDeliveryMethod(row) {
      return row.deliveryMethod && row.deliveryMethod.name;
    },
    getCompanyInfo(order) {
      const company = order.company || order.subCompany;
      return company.shortName;
    },
    getOrderWeight(order) {
      return order.weight || 0;
    },
    numberCreator(value) {
      return orderNumberCreator(value);
    },
    numberFlatter(value) {
      return orderNumberFlatter(value);
    },
    orderNumber(number) {
      return orderNumberCreator(number);
    },
    getOrderCategory(row) {
      //had no idea how to set default shipment date in other place, well i set in in first function
      if (!row.shipmentDate) {
        row.shipmentDate = moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format("YYYY-MM-DD");
      }
      return productTypeFullNameByOrderCategory[row.category];
    },
    getOrderDate(row) {
      return moment(row.productionDate, "DD-MM-YYYY hh:mm:ss").format(
        "YYYY-MM-DD"
      );
    },
    checkAll(event, list) {
      if (event) {
        this.selectedOrders = list.map(item => item.id);
      } else {
        this.selectedOrders = [];
      }
    },
    countOrderItems(row) {
      const items = this.getOrderItems(row);
      if (!items) {
        return 0;
      }
      let counter = 0;
      for (const idx in items) {
        counter += items[idx].quantity;
      }
      return counter;
    },
    getOrderItems(order) {
      return orderItemGetter(order);
    },
    showOrder(row) {
      this.orderId = row.id;
      this.isModalActive = true;
    },
    editOrder(row) {
      this.editOrderId = row.id;
      this.isEditOrderModalActive = true;
    },
    onEditOrderClose() {
      this.editOrderId = null;
      this.isEditOrderModalActive = false;
      this.$store.dispatch("logisticList/fetch");
    },
    confirmOrders() {
      if (this.selectedOrders.length < 1) {
        notify("Nie zaznaczono żadnego zamówienia!", "warning");
        return;
      }

      if (!this.ordersAreConfirmed) {
        notify(
          "Nie zatwierdzono ustawienia daty wysyłki dla zaznaczonych zamówień!",
          "warning"
        );
        return;
      }
      let ordersToSend = [];
      const orders = this.$store.getters["logisticList/list"];
      for (const idx in this.selectedOrders) {
        const order = orders.find(el => el.id === this.selectedOrders[idx]);
        if (order) {
          ordersToSend.push(order);
        }
      }
      ordersToSend = ordersToSend.map(el => {
        return {
          id: el.id,
          shipmentDate: el.shipmentDate
        };
      });
      const body = { orders: ordersToSend };
      http
        .post("order_logistics", body)
        .then(() => {
          notify("Ustawiono daty wysyłki dla wybranych zamówień.");
          this.selectedOrders = [];
          this.ordersAreConfirmed = false;
          this.$store.dispatch("logisticList/fetch");
        })
        .catch(() => {
          notify("Podczas ustawiania daty zamówień wystąpił błąd.", "warning");
        });
    }
  },
  watch: {
    "$store.state.route.query": function() {
      this.selectedOrders = [];
    }
  },
  created() {
    this.$store.dispatch("logisticList/fetch");

    let types = [];
    types.push({ label: "Wszystkie", value: null });
    http
      .get("/delivery_methods?properties[]=id&properties[]=name")
      .then(response => {
        if (response.data["hydra:member"]) {
          response.data["hydra:member"].forEach(type => {
            types.push({
              label: type.name,
              value: type.id
            });
          });
          this.deliveryMethodFilterValues = types;
        }
      })
  }
};
</script>
