<template>
  <div class="is-fullheight user-color-theme is-100vh" :style="colorTheme">
    <Navbar v-if="showLayout"/>
    <div v-if="showLayout">
      <div class="is-fullhd" :class="{'container': shouldBeWider}">
        <div class="columns is-fullheight">
          <div class="column has-max-width-medium has-padding-top-small has-padding-left-small"
               :class="{'is-2': shouldBeWider}">
            <Sidebar/>
          </div>
          <div class="column has-padding-right-small" :class="{'is-10': shouldBeWider}">
            <div class="box">
              <router-view :key="$route.name"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>
<script>
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    Sidebar,
    Navbar
  },
  computed: {
    showLayout() {
      return (
          this.$store.state.auth.isLoggedIn && this.$route.name !== "forbidden"
      );
    },
    ...mapGetters("auth", ["userId"]),
    ...mapGetters(["themeBackgroundColor"]),
    colorTheme() {
      if (!this.backgroundColor) {
        return {
          "--user-bg-color": this.themeBackgroundColor
        };
      }
      return {
        "--user-bg-color": "white"
      };
    },
    shouldBeWider() {
      return (
          !this.isProduction &&
          !this.isFabricShadePriceList &&
          !this.isExternalShutterPriceList &&
          !this.isFabricShadeDayNightPriceList &&
          !this.isStatistics &&
          !this.isOrders &&
          !this.isCompanies &&
          !this.isPleatPriceList
      );
    },
    isProduction() {
      return this.$route.path.includes("production");
    },
    isFabricShadePriceList() {
      return this.$route.path.includes("fabric_shade_price_lists/") && this.$route.path.includes("/update");
    },
    isFabricShadeDayNightPriceList() {
      return this.$route.path.includes("fabric_shade_day_night_price_lists/") && this.$route.path.includes("/update");
    },
    isExternalShutterPriceList() {
      return this.$route.path.includes("external_shutter_price_lists/") && (this.$route.path.includes("/update") || this.$route.path.includes("/create"));
    },
    isPleatPriceList() {
      return this.$route.path.includes("pleat_price_lists/") && (this.$route.path.includes("/update") || this.$route.path.includes("/create"));
    },
    isStatistics() {
      return this.$route.path.includes("statistics/") && (this.$route.path.includes("/list") || (this.$route.path.includes("/main")));
    },
    isOrders() {
      return this.$route.path.includes("orders/");
    },
    isCompanies() {
      return (this.$route.path.includes("companies/") || this.$route.path.includes("subCompanies/"));
    }

  },
  methods: {
    ...mapActions(["init"])
  },
  created() {
    if (this.$store.state.auth.isLoggedIn) {
      this.init(this.userId);
    }
  }
};
</script>
<style>
.user-color-theme {
  background-color: var(--user-bg-color) !important;
}
</style>
