import Vue from "vue";
import App from "./App.vue";

import VueHtmlToPaper from "vue-html-to-paper";
import VueCurrencyInput from "vue-currency-input";
import CKEditor from "@ckeditor/ckeditor5-vue2";

const location = window.location;
const printCssUrl =
  location.protocol + "//" + location.host + "/" + "print.css";

require("datejs");

const htmlToPaperOptions = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    printCssUrl,
    "https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.css"
  ]
};
Vue.use(CKEditor);

Vue.use(VueHtmlToPaper, htmlToPaperOptions);

Vue.use(VueCurrencyInput);

import Buefy from "buefy";
import "./scss/main.scss";
Vue.use(Buefy);

import AppComponents from "./plugins/app";
Vue.use(AppComponents);

import router from "./router";
import store from "./store";
import http from "./http";
import "./registerServiceWorker";

import "./modules/common";
import "./modules/orders";
import "./modules/dashboard";
import "./modules/fabric_shades";
import "./modules/management";
import "./modules/auth";
import "./modules/settings";
import "./modules/statistics";
import "./modules/global";
import "./modules/other";
import "./modules/configuration";
import "./modules/production";
import "./modules/external_shutters";
import "./modules/fabric_shade_day_nights";
import "./modules/application";
import "./modules/pleats";
import "./modules/details";
import "./modules/frame_mosquito_nets";
import "./modules/roll_mosquito_nets";

import notify from "./helpers/notify";

if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  };
}

String.prototype.countRepeatsOnBegin = function(search) {
  let counter = 0;
  for (let i = 0; i < this.length; i++) {
    if (this[i] === search) {
      counter++;
    } else {
      break;
    }
  }

  return counter;
};

Vue.config.productionTip = false;
Vue.prototype.$notify = notify;
Vue.prototype.$http = http;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
