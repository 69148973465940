<template lang="pug">
  b-modal(:active.sync="isActive" @close="onClose")
    app-view-loadable(:loading="loading")
      app-view-card(v-if="orderObject" :title="'Edycja zamówienia: ' + getOrderNumber(orderObject)")
        template(slot="icon")
          b-button(
            @click="onClose"
          )
            b-icon(icon="close" size="is-small")
        app-form(@close="onClose" v-bind="props" :model="orderModel" :object="orderObject" resource="orders" :formDataShouldBeObject="true" :transform-to-model="transformToModel" :transform-to-submit="transformToSubmit")
          template(#default="{ data, errors }")
            .columns
              .column
                app-form-field(label="Data Produkcji" field="productionDate" :errors="errors")
                  date-picker(
                    v-model="data.productionDate"
                    type="date"
                    value-type="format"
                    format="DD-MM-YYYY"
                    :disabled-date="(date) => !date.getDay()"
                    :disabled="isLogistic"
                  )
                app-form-field(v-if="isLogistic" label="Sposób dostawy" field="deliveryMethod" :errors="errors")
                  resource-select(
                    v-if="data && isActive"
                    v-model="data.deliveryMethod"
                    resource="delivery_methods"
                    url="delivery_methods"
                    placeholder="Wybierz sposób dostawy"
                    label="name"
                    :extra-props="getDeliveryMethodResourceSelectExtraProps()"
                  )

                .columns(v-if="data.category && data.productionStatus !== 'end'")
                  .column
                    app-form-field(label="Monterzy" field="fitterProductionWorkers" :errors="errors")
                      multiple-resource-select(
                        v-model="data.fitterProductionWorkers"
                        resource="production_workers"
                        url="production_workers"
                        placeholder="Wybierz pracowników"
                        :extra-props="getFitterQuery()"
                        :label="item => `${item.firstName} ${item.lastName}`"
                        @fetched="onFittersFetched"
                      )
                  .column
                    app-form-field(label="Pilarze" field="sawyerProductionWorkers" :errors="errors")
                      multiple-resource-select(
                        v-model="data.sawyerProductionWorkers"
                        resource="production_workers"
                        url="production_workers"
                        placeholder="Wybierz pracowników"
                        :extra-props="getSawyerQuery()"
                        :label="item => `${item.firstName} ${item.lastName}`"
                        @fetched="onSawyersFetched"
                      )
              .column
                app-form-field(field="company.shortName" label="Firma")
                  b-input(:value="getCompanyShortName(data)" disabled)
                app-form-field(field="category" label="Typ produktu")
                  b-input(:value="getOrderCategory(data)" disabled)
                app-form-field(field="itemsCount" label="Sztuk")
                  b-input(:value="getOrderItemsCount(data)" disabled)
                app-form-field(field="productionLine" label="Linia produkcyjna")
                  resource-select(
                    v-if="data && isActive"
                    v-model="data.productionLine"
                    resource="production_lines"
                    url="production_lines"
                    placeholder="Wybierz linię produkcyjną"
                    label="name"
                    :extra-props="getProductionLineResourceSelectExtraProps(data)"
                  )
                app-form-field(label="Notatka" field="comments" :errors="errors")
                  b-input(v-model="data.comments" type="textarea" disabled)
        template(v-if="isDeletable" )
          b-button(
            type="is-danger"
            @click.prevent="onDelete"
          ) Usuń


</template>

<script>
import orderNumberCreator from "../../../helpers/orderNumberCreator";
import form from "../../../plugins/app/form/mixins/form";
import http from "../../../http";
import generateProperties from "../../../helpers/generateProperties";
import notify from "../../../helpers/notify";
import MultipleResourceSelect from "./MultipleResourceSelect";
import ResourceSelect from "./ResourceSelect";
import DatePicker from "vue2-datepicker";
import productTypeCodesByOrderCategory from "../../../helpers/productTypeCodesByOrderCategory";
import simpleClone from "../../../helpers/simpleClone";
import orderItemGetter from "../../../helpers/orderItemGetter";

export default {
  components: {MultipleResourceSelect, ResourceSelect, DatePicker},
  mixins: [form],
  props: {
    active: {type: Boolean, default: false, required: true},
    orderId: {default: null, required: true},
    isLogistic: {type: Boolean, default: false}
  },
  data() {
    return {
      isActive: this.active,
      loading: false,
      orderObject: null,
      originalFitterProdWorkers: [],
      originalSawyerProdWorkers: [],
      orderModel: {
        id: null,
        productionDate: null,
        sawyerProductionWorkers: [],
        fitterProductionWorkers: [],
        company: {},
        subCompany: {},
        category: null,
        productionStatus: "",
        productionLine: {},
        comments: '',
        deliveryMethod: null
      }
    };
  },
  methods: {
    getProductTypeCodeByOrderCategory(order) {
      if (order && order.category) {
        return productTypeCodesByOrderCategory[order.category];
      } else {
        return "RTK";
      }
    },
    getProductionLineResourceSelectExtraProps(data) {
      return `&properties[]=code&properties[]=lineColor&productType.code[]=${this.getProductTypeCodeByOrderCategory(data)}`;
    },
    onSawyersFetched(sawyers) {
      for (const idx in this.originalSawyerProdWorkers) {
        if (sawyers.findIndex(el => el.id === this.originalSawyerProdWorkers[idx].id) < 0) {
          sawyers.push(this.originalSawyerProdWorkers[idx]);
        }
      }
    },
    onFittersFetched(fitters) {
      for (const idx in this.originalFitterProdWorkers) {
        if (fitters.findIndex(el => el.id === this.originalFitterProdWorkers[idx].id) < 0) {
          fitters.push(this.originalFitterProdWorkers[idx]);
        }
      }
    },
    getSawyerQuery() {
      return `&properties[]=id&properties[]=firstName&properties[]=lastName&sawyerProductTypes.code=${this.getProductTypeCodeByCategory(this.orderObject)}&isEnabled=true`
    },
    getFitterQuery() {
      return `&properties[]=id&properties[]=firstName&properties[]=lastName&fitterProductTypes.code=${this.getProductTypeCodeByCategory(this.orderObject)}&isEnabled=true`
    },
    getProductTypeCodeByCategory(data) {
      let code = productTypeCodesByOrderCategory[data.category];
      return code || "RTK";
    },

    onClose() {
      this.isActive = false;
      this.$emit("close");
    },
    async onDelete() {
      if (!this.isDeletable) {
        return;
      }
      const success = await http
          .delete(`/orders/${this.orderId}`)
          .then(response => {
            notify('Usunięto zamówienie.')
            this.isActive = false;
            this.orderObject = null;
            this.$emit("close");
          })
          .catch(() => {
            notify("Nie udało się usunąć zamówienia.", "warning");
          });
    },
    async downloadOrder(activator, value) {
      try {
        this.loading = true;
        let {data} = await http.get(
            `/api/production/orders/${this.orderId}/view/edit`
        );
        this.orderObject = data;
        if (activator === "active") {
          if (this.isActive !== !!value) {
            this.isActive = !!value;
          }
        }
      } catch {
        this.orderObject = null;
        notify(
            "Nie udało się załadować danych do podglądu zamówienia",
            "danger"
        );
        this.$emit("close");
      } finally {
        this.loading = false;
      }
    },
    getCompanyShortName(order) {
      if (!order) {
        return "";
      }
      if (order.company) {
        return order.company.shortName;
      }
      if (order.subCompany) {
        return order.subCompany.shortName;
      }
      return "";
    },
    getOrderItemsCount(order) {

      if ('FixedOrder' === order['@type']) {
        return order.quantity;
      }
      const orderItems = this.getOrderItems(order);
      let count = 0;
      if (orderItems.length < 1) {
        return count;
      }
      for (let item in orderItems) {
        count += orderItems[item].quantity;
      }
      return count;
    },
    getOrderNumber(order) {
      if (order && order.number) {
        return orderNumberCreator(order.number);
      } else {
        return "";
      }
    },
    getOrderItems(order) {
      if (!order) {
        return [];
      }

      return orderItemGetter(order)
    },
    getOrderCategory(row) {
      return productTypeCodesByOrderCategory[row.category] || "";
    },
    transformToModel(data) {

      const prodWorkerIri = '/production_workers/';

      if (data.productionDate) {
        data.productionDate = data.productionDate.split(" ")[0];
      }

      this.originalFitterProdWorkers = simpleClone(data.fitterProductionWorkers);
      this.originalSawyerProdWorkers = simpleClone(data.sawyerProductionWorkers);

      data.sawyerProductionWorkers = data.sawyerProductionWorkers.map(worker =>
          worker["@id"] ? worker["@id"] : worker
      );
      data.fitterProductionWorkers = data.fitterProductionWorkers.map(worker =>
          worker["@id"] ? worker["@id"] : worker
      );

      // if(data.productionLine) {
      //   data.productionLine['@id'] = '/production_lines/' + data.productionLine['id'];
      // }

      return data;
    },
    transformToSubmit(data) {
      let validationData = new FormData();
      validationData.append('productionTime', data.productionTime);
      validationData.append('productType', productTypeCodesByOrderCategory[data.category]);
      if (data.productionLine["@id"]) {
        validationData.append('productionLine', parseInt(data.productionLine["@id"].split('/')[2]));
      }
      else {
        validationData.append('productionLine', parseInt(data.productionLine.split('/')[2]));
      }
      validationData.append('productionDate', data.productionDate);


      let {dataValidated} = http.post(
          '/api/validate/production/time',
          validationData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Accept": "application/ld+json"
            }
          }
      );

      const prodWorkerIri = '/production_workers/';

      data.sawyerProductionWorkers = data.sawyerProductionWorkers.map(worker =>
          worker["@id"] ? worker["@id"] : worker["id"] ? prodWorkerIri + worker.id : worker
      );

      data.fitterProductionWorkers = data.fitterProductionWorkers.map(worker =>
          worker["@id"] ? worker["@id"] : worker["id"] ? prodWorkerIri + worker.id : worker
      );

      delete data.company;
      delete data.subCompany;
      delete data.category;
      delete data.fabricShadeOrderItems;
      delete data.externalShutterOrderItems;
      delete data.fabricShadeDayNightOrderItems;
      delete data.pleatOrderItems;
      delete data.detailOrderItems;

      if (!this.isLogistic) {
        delete data.deliveryMethod;
      }

      return data;
    },
    getDeliveryMethodResourceSelectExtraProps() {
      return generateProperties([], ['id', 'name'], true)
    }
  },
  computed: {
    isDeletable() {
      if (this.orderObject && "FixedOrder" === this.orderObject['@type']) {
        return true;
      }

      return false;
    }
  },
  watch: {
    orderId(value) {
      if (value && !isNaN(value) && this.active) {
        this.downloadOrder("id");
      }
    },
    active(value) {
      if (value && !isNaN(value) && this.active && value && this.orderId) {
        this.downloadOrder("active", value);
      }
    }
  }
};
</script>
